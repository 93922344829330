import React, {useEffect, useState} from 'react';
import adminService from '../../services/adminService';
import {Author, User} from '../../interfaces/interfaces';
import styles from './AuthorList.module.css';
import AuthorModal from './AuthorModal';

const AuthorList: React.FC = () => {
    const [authors, setAuthors] = useState<Author[]>([]);
    const [totalAuthors, setTotalAuthors] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingAuthor, setEditingAuthor] = useState<Author | null>(null);

    const fetchAuthors = async (page: number) => {
        const {authors, totalCount} = await adminService.getAuthorsWithUsers(page, pageSize);
        setAuthors(authors);
        setTotalAuthors(totalCount);
    };

    const handleCreateAuthor = () => {
        setEditingAuthor(null); // Clear editing author
        setIsModalOpen(true);
    };

    const handleEditAuthor = (author: Author) => {
        setEditingAuthor(author);
        setIsModalOpen(true);
    };

    const handleDeleteAuthor = async (authorId: string) => {
        if (window.confirm('Are you sure you want to delete this author?')) {
            try {
                await adminService.deleteAuthor(authorId);
                fetchAuthors(currentPage);
            } catch (error) {
                alert('Failed to delete author.');
            }
        }
    };

    const handleSaveAuthor = async (author: Partial<Author>) => {
        try {
            if (editingAuthor) {
                await adminService.updateAuthor(editingAuthor.id, author);
            } else {
                await adminService.createAuthor(author);
            }
            setIsModalOpen(false);
            fetchAuthors(currentPage);
        } catch (error) {
            alert('Failed to save author.');
        }
    };

    useEffect(() => {
        fetchAuthors(currentPage);
    }, [currentPage]);

    const totalPages = Math.ceil(totalAuthors / pageSize);

    return (
        <div className={styles.authorListContainer}>
            <div className={styles.header}>
                <h2>Author List</h2>
                <button onClick={handleCreateAuthor} className={styles.createButton}>
                    Create New Author
                </button>
            </div>
            <table className={styles.authorTable}>
                <thead>
                <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Title</th>
                    <th>ID</th>
                    <th>Has User</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {authors.map((author) => (
                    <tr key={author.id}>
                        <td>
                            {author.photo ? (
                                <img
                                    src={author.photo}
                                    alt={author.name}
                                    className={styles.authorPhoto}
                                />
                            ) : (
                                <span className={styles.noPhoto}>No Photo</span>
                            )
                            }
                        </td>
                        <td>{author.name}</td>
                        <td>{author.title || 'N/A'}</td>
                        <td>{author.id}</td>
                        <td>{author.userId ? 'Yes' : 'No'}</td>
                        <td>
                            <button
                                onClick={() => handleEditAuthor(author)}
                                className={styles.editButton}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteAuthor(author.id)}
                                className={styles.deleteButton}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className={styles.pagination}>
                <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                    Next
                </button>
            </div>
            {isModalOpen && (
                <AuthorModal
                    author={editingAuthor}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSaveAuthor}
                />
            )}
        </div>
    );
};

export default AuthorList;

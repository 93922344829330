//client/src/utils/analytics.ts
import ReactGA from 'react-ga4';
import {useStatsigClient, useStatsigUser} from '@statsig/react-bindings';
import {useCallback} from "react";


const isProduction = process.env.REACT_APP_ENV === 'production';

export const initializeGA = (measurementId: string) => {
    if (isProduction && measurementId) {
        ReactGA.initialize(measurementId);
        ReactGA.send("pageview");
    }
};

export const trackPageView = (path: string) => {
    if (isProduction) {
        ReactGA.send({hitType: "pageview", page: path});
    }
};

export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
    if (isProduction) {
        ReactGA.event({
            category,
            action,
            label,
            value,
        });
    }
};

export const useLogEvent = () => {
    const {logEvent} = useStatsigClient();
    return (eventName: string, value: string, metadata: Record<string, any>) => {
        logEvent(eventName, value, metadata);
    };
};

export const useSetUserProperties = () => {
    const { updateUserAsync } = useStatsigUser();

    // Use useCallback to memoize the function
    return useCallback(
        (userId: string, properties: Record<string, any>) => {
            console.log('Calling updateUserAsync with:', { userId, properties });

            updateUserAsync({
                userID: userId,
                ...properties,
            });
        },
        [updateUserAsync] // Dependency ensures the function only changes if `updateUserAsync` changes
    );
};
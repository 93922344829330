import React from 'react';
import UserGraph from './UserGraph';
import AuthorGraph from './AuthorGraph';
import BookGraph from './BookGraph';
import styles from './Stats.module.css';

const Stats: React.FC = () => {
    return (
        <div className={styles.statsContainer}>
            <h2 className={styles.title}>Statistics</h2>
            <div className={styles.graphs}>
                <div className={styles.graphCard}>
                    <UserGraph />
                </div>
                <div className={styles.graphCard}>
                    <AuthorGraph />
                </div>
                <div className={styles.graphCard}>
                    <BookGraph />
                </div>
            </div>
        </div>
    );
};

export default Stats;

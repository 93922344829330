export enum EventCategory {
    USER_INTERACTION = "User Interaction",
    FORM_ACTION = "Form Action",
}

export enum EventAction {
    BUTTON_CLICK = "Button Click",
    FORM_SUBMISSION = "Form Submission",
    LOGIN_CLICK = "Login Click",
    BOOK_TAB_CLICK = "Book Tab Clicked",
    PRACTICES_CLICKED = "Practices Clicked",
    MESSAGE_SENT = "Message Sent",
}

export enum EventLabel {
    SUBMIT_BUTTON = "Submit Button",
    CANCEL_BUTTON = "Cancel Button",
    FAIL = "Fail",
    SUCCESS = "Success",
}

//client/src/services/chatService.ts

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    return token;
};

const config = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

export interface ChatMessage {
    role: 'user' | 'assistant' | 'system';
    content: string;
    audio?: string;
}

const sendMessage = async (token: string, bookId: string, message: string): Promise<ChatMessage> => {
    const response = await axios.post(
        `${API_URL}/chat`,
        { message, bookId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data;
};

const getAudio = async (token: string, text: string, bookId: string): Promise<string> => {
    const response = await axios.post(
        `${API_URL}/tts`,
        { text, bookId },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    return response.data.audio;
};


const fetchHistory = async (bookId: string) => {
    try {
        const response = await axios.get(`${API_URL}/chat/${bookId}/history`, config());
        return response.data;
    } catch (error) {
        console.error('Error fetching chat history:', error);
        throw error;
    }
};

const clearHistory = async (bookId: string) => {
    try {
        const response = await axios.delete(`${API_URL}/chat/${bookId}/history`, config());
        return response.data;
    } catch (error) {
        console.error('Error fetching chat history:', error);
        throw error;
    }
};

const chatService = {
    fetchHistory,
    sendMessage,
    getAudio,
    clearHistory,
};

export default chatService;

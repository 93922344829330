// src/components/authorPage/BookSection.tsx

import React, {useEffect, useState} from 'react';
import styles from './BookSection.module.css';

import {ReactComponent as LearningsIcon} from '../../assets/icons/learnings.svg';
import {ReactComponent as InsightsIcon} from '../../assets/icons/insights.svg';
import {ReactComponent as ToolsIcon} from '../../assets/icons/tools.svg';
import divider from '../../assets/icons/divider-gradient.svg';
import Learnings from './Learnings';
import Insights from './Insights';
import Tools from './Tools';

import {CSSTransition, SwitchTransition} from 'react-transition-group';
import bookService from "../../services/bookService";
import {Book} from "../../interfaces/interfaces";
import {useAuthor} from "../../context/AuthorContext";
import {useLogEvent} from "../../utils/analytics";
import {EventAction} from "../../constants/analytics";
import {useMediaQuery} from "react-responsive";
import ToolsMobile from "./ToolsMobile";
import BookCarousel from "../common/BookCarousel";
import Loading from "../loader/Loading";
import {useLogRenders} from "../../utils/debugLogger";

interface BookSectionProps {
    bookId: string | null;
    setBookId: (bookId: string | null) => void;
    clearChat: () => void;
}

const tabs = [
    {
        key: 'learnings',
        label: 'Learnings',
        icon: LearningsIcon,
        color: '#21808D',
    },
    {
        key: 'insights',
        label: 'Insights',
        icon: InsightsIcon,
        color: '#C87156',
    },
    {
        key: 'tools',
        label: 'Practice',
        icon: ToolsIcon,
        color: '#6F499D',
    },
];

const BookSection: React.FC<BookSectionProps> = ({ bookId, setBookId, clearChat }) => {    const logEvent = useLogEvent();
    const {author, books, loading: authorLoading, error} = useAuthor();
    const [book, setBook] = useState<Book | null>(null);
    const [learnings, setLearnings] = useState([]);
    const [insights, setInsights] = useState([]);
    const [tools, setTools] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [activeTab, setActiveTab] = useState<'learnings' | 'insights' | 'tools'>('learnings');
    const isMobile = useMediaQuery({maxWidth: 768});
    const [loadingBookData, setLoadingBookData] = useState(false); // Add loading state

    useLogRenders('BookSection');

    useEffect(() => {
        const fetchData = async () => {
            if (!bookId) {
                // Reset state if no bookId
                setBook(null);
                setLearnings([]);
                setInsights([]);
                setTools([]);
                setDataLoading(false);
                setLoadingBookData(false); // Ensure loading state is reset
                return;
            }

            setDataLoading(true);
            setLoadingBookData(true); // Set loading to true when fetching new data

            try {
                const [bookData, learningsData, insightsData, toolsData] = await Promise.all([
                    bookService.getBook(bookId),
                    bookService.getBookLearnings(bookId),
                    bookService.getBookInsights(bookId),
                    bookService.getBookTools(bookId),
                ]);
                setBook(bookData);
                setLearnings(learningsData);
                setInsights(insightsData);
                setTools(toolsData);
            } catch (error) {
                console.error('Error fetching book data:', error);
            } finally {
                setDataLoading(false);
                setLoadingBookData(false); // Set loading to false after fetching
            }
        };

        fetchData();
    }, [bookId]);

    const isDataLoading = authorLoading || dataLoading;
    if (isDataLoading) {
        // return <div>Loading...</div>;
    }

    if (error || !author || !books) {
        // return <div>Error loading books and profile.</div>;
    }


    return (
        <div className={styles.bookSection}>
            <div className={styles.topBookSection}>
                <BookCarousel books={books} currentBookId={bookId} setBookId={setBookId} />
                <div className={styles.clearChat} onClick={clearChat}>
                    Clear Chat
                </div>
            </div>

            <img
                src={divider}
                alt="Divider"
                className={`${styles.divider} ${styles.dividerMobile}`}
            />

            <div className={styles.tabContainer}>
                {tabs.map((tab) => {
                    const IconComponent = tab.icon;
                    const isActive = tab.key === activeTab;

                    return (
                        <button
                            key={tab.key}
                            className={`${styles.tab} ${isActive ? styles.activeTab : ''}`}
                            style={{
                                background: isActive && isMobile ? tab.color : 'none',
                            }}
                            onClick={() => {
                                setActiveTab(tab.key as 'learnings' | 'insights' | 'tools');
                                logEvent(EventAction.BOOK_TAB_CLICK, tab.key, {
                                    book: book?.title,
                                    author: author!.name,
                                    bookId,
                                    authorId: author!.id
                                });
                            }}
                        >
                            <IconComponent
                                className={styles.icon}
                                style={{
                                    color: isActive ? tab.color : '#1E3742',
                                    opacity: isActive ? 1 : 0.5,
                                }}
                            />
                            <span
                                className={styles.tabText}
                                style={{
                                    color: isActive ? tab.color : '#1E3742',
                                    opacity: isActive ? 1 : 0.5,
                                }}
                            >
                                {tab.label}
                            </span>
                            {isActive && (
                                <div
                                    className={styles.activeIndicator}
                                    style={{backgroundColor: tab.color}}
                                ></div>
                            )}
                        </button>
                    );
                })}
            </div>

            <img
                src={divider}
                alt="Divider"
                className={`${styles.divider} ${styles.dividerDesktop}`}
            />

            <div className={styles.contentContainer}>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={activeTab}
                        timeout={200}
                        classNames={{
                            enter: styles.fadeEnter,
                            enterActive: styles.fadeEnterActive,
                            exit: styles.fadeExit,
                            exitActive: styles.fadeExitActive,
                        }}
                        unmountOnExit
                    >
                        <div className={styles.transitionWrapper}>
                            {activeTab === 'learnings' && <Learnings learnings={learnings} />}
                            {activeTab === 'insights' && <Insights insights={insights} />}
                            {activeTab === 'tools' &&
                                (isMobile ? (
                                    <ToolsMobile tools={tools} />
                                ) : (
                                    <Tools tools={tools} />
                                ))}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    );
};

export default React.memo(BookSection);

// File: client/components/MainContent.tsx
import React, {useRef, useEffect, useState, useCallback} from 'react';
import BookSection from './BookSection';
import ChatComponent from './ChatComponent';
import styles from './MainContent.module.css';
import {ChatProvider, useChat} from '../../context/ChatContext';
import {ReactComponent as SendArrowIcon} from '../../assets/icons/arrow-send.svg';
import {useAuthor} from '../../context/AuthorContext';
import {useLogEvent} from "../../utils/analytics";
import {EventAction} from "../../constants/analytics";
import {useLogRenders} from "../../utils/debugLogger";

const MainContent: React.FC = () => {
    const { author, books, loading, error } = useAuthor();
    const logEvent = useLogEvent();
    useLogRenders('MainContent');

    const buildStorageKey = (authorId: string | undefined) => {
        return authorId ? `chosenBookId_${authorId}` : 'chosenBookId_unknown';
    };

    const [bookId, setBookId] = useState<string | null>(null);

    useEffect(() => {
        // If still loading or no author, do nothing
        if (loading || !author?.id) {
            setBookId(null);
            return;
        }
        // If books are empty, nothing to set
        if (!books || books.length === 0) {
            setBookId(null);
            return;
        }

        const storageKey = buildStorageKey(author.id);
        const storedBookId = localStorage.getItem(storageKey);

        if (storedBookId) {
            // If we already have a storedBookId for this author, use it
            console.log(`[MainContent] Using storedBookId for author ${author.id}:`, storedBookId);
            setBookId(storedBookId);
        } else {
            // If there's no storedBookId for this author, pick the first available book
            console.log(`[MainContent] No storedBookId; using the first book for author ${author.id}:`, books[0].id);
            setBookId(books[0].id);
        }
    }, [loading, author, books]);

    // 4. Whenever we get a valid bookId and authorId, save it to localStorage
    useEffect(() => {
        if (!author?.id || !bookId) return;
        const storageKey = buildStorageKey(author.id);
        console.log('[MainContent] Storing bookId:', bookId, 'for author:', author.id);
        localStorage.setItem(storageKey, bookId);
    }, [bookId, author]);

    const authorId = author?.id;

    const scrollableContentRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const memoizedSetBookId = useCallback((newBookId: string | null) => {
        console.log('[MainContent] memoizedSetBookId:', newBookId);
        setBookId(newBookId);
    }, []);

    // Child component that manages chat
    const ChatContent = () => {
        const {
            messages,
            input,
            setInput,
            sendMessage,
            loading,
            clearChat,
        } = useChat();

        const prevMessagesLength = useRef<number>(0);
        const prevLoading = useRef<boolean>(false);

        useEffect(() => {
            const diffMessages = messages.length - prevMessagesLength.current;
            const shouldScroll = (diffMessages > 1 && diffMessages <= 2) || prevLoading.current !== loading;
            if (scrollableContentRef.current && shouldScroll) {
                scrollableContentRef.current.scrollTo({
                    top: scrollableContentRef.current.scrollHeight,
                    behavior: 'smooth',
                });
                prevLoading.current = loading;
            }
            prevMessagesLength.current = messages.length;
        }, [messages, loading]);

        // Auto-resize textarea
        useEffect(() => {
            if (textareaRef.current) {
                textareaRef.current.style.height = 'auto';
                const maxHeight = 20 * 4 + 24; // 20px line-height * 4 lines + padding
                textareaRef.current.style.height = Math.min(textareaRef.current.scrollHeight, maxHeight) + 'px';
            }
        }, [input]);

        const handleSendMessage = () => {
            if (input.trim() !== '' && bookId) {
                console.log('[ChatContent] Sending message. Input:', input);
                sendMessage();
                logEvent(EventAction.MESSAGE_SENT, `Message size: ${input.length} chars`, {
                    messages: messages.length,
                    author: author?.name,
                    book: books?.find((book) => book.id === bookId)?.title,
                    authorId,
                    bookId
                });
                if (textareaRef.current) {
                    textareaRef.current.style.height = 'auto';
                }
            }
        };

        const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
            }
        };

        return (
            <>
                <div className={styles.scrollableContent} ref={scrollableContentRef}>
                    <div className={styles.bookSection}>
                        <BookSection bookId={bookId} setBookId={memoizedSetBookId} clearChat={clearChat} />
                    </div>
                    <div className={styles.chatComponent}>
                        <ChatComponent/>
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <textarea
                        ref={textareaRef}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleInputKeyDown}
                        placeholder="Ask anything..."
                        className={styles.inputField}
                        rows={1}
                    />
                    <div className={styles.toggleContainer}>
                        <input type="checkbox" id="confidentToggle" className={styles.toggleSwitch} />
                        <label htmlFor="confidentToggle" className={styles.toggleLabel}>
                            Confident
                        </label>
                    </div>
                    <button
                        onClick={handleSendMessage}
                        className={styles.sendButton}
                        disabled={loading || !bookId}
                    >
                        <SendArrowIcon className={styles.sendArrowIcon}/>
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className={styles.mainContent}>
            <ChatProvider authorId={authorId} bookId={bookId}>
                <ChatContent />
            </ChatProvider>
        </div>
    );
};

export default MainContent;
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.css';

const Sidebar: React.FC = () => {
    const menuItems = [
        { path: 'stats', label: 'Dashboard' },
        { path: 'users', label: 'Users' },
        { path: 'authors', label: 'Authors' },
        { path: 'books', label: 'Books' },
    ];

    return (
        <nav className={styles.sidebar}>
            <div className={styles.logo}>
                <h1>Admin Panel</h1>
            </div>
            <ul className={styles.menu}>
                {menuItems.map((item) => (
                    <li key={item.path}>
                        <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                                isActive ? styles.activeLink : styles.link
                            }
                        >
                            {item.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Sidebar;

// src/components/authorPage/ToolsMobile.tsx
import React from 'react';
import styles from './ToolsMobile.module.css';
import {ReactComponent as InteractiveDecision} from '../../assets/icons/interactive-decision.svg';
import {ReactComponent as MsgAssesment} from '../../assets/icons/msg-assesment.svg';
import {ReactComponent as V2Mom} from '../../assets/icons/v2mom.svg';
import {ReactComponent as PlayIcon} from '../../assets/icons/play.svg';
import {EventAction} from '../../constants/analytics';
import {useLogEvent} from '../../utils/analytics';

const icons = [
    { icon: InteractiveDecision, color:"#D9D9D9"},
    { icon: MsgAssesment, color:"#D9D9D9"},
    { icon: V2Mom, color:"#D9D9D9"}
];

interface Tool {
    id: string;
    name: string;
    description?: string;
}

interface ToolsMobileProps {
    tools: Tool[];
}

const ToolsMobile: React.FC<ToolsMobileProps> = ({tools}) => {
    const logEvent = useLogEvent();

    return (
        <div className={styles.toolsMobileContainer}>
            {tools.map((tool, index) => {
                const IconComponent = icons[index % icons.length].icon;

                return (
                    <div key={tool.id} className={styles.toolCard}>
                        <div className={styles.iconContainer}>
                            <IconComponent className={styles.toolIcon}/>
                        </div>

                        <div className={styles.textContainer}>
                            <div className={styles.toolTitle}>{tool.name}</div>
                            <div className={styles.toolSubtitle}>{tool.description}</div>
                        </div>

                        <div className={styles.divider}/>
                        <PlayIcon
                            className={styles.playButton}
                            onClick={() => logEvent(EventAction.PRACTICES_CLICKED, tool.name, {toolId: tool.id})}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ToolsMobile;

import {User} from "../interfaces/interfaces";

export const generateUUID = (): string => {
    if (typeof crypto !== 'undefined' && crypto.randomUUID) {
        return crypto.randomUUID();
    }
    console.warn('crypto.randomUUID() is not supported in this browser. Falling back to manual UUID generation.');
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const getUserID = (): string => {
    const userIdKey = 'wisdomcom_user_id';

    // Step 1: Check if user ID already exists in localStorage
    const cachedUser: User | null = getCachedUser()
    if (cachedUser && cachedUser.id) {
        localStorage.setItem(userIdKey, cachedUser.id);
        return cachedUser.id;
    }

    let userId = localStorage.getItem(userIdKey);

    if (!userId) {
        // Step 2: Generate a new UUID if none exists
        userId = generateUUID();

        // Step 3: Store it in localStorage for persistence
        try {
            localStorage.setItem(userIdKey, userId);
        } catch (error) {
            console.error('Failed to store user ID in localStorage:', error);
        }
    }

    return userId;
};

export const getCachedUserWithTime = (): { user: User | null, timestamp: number } => {
    const cachedData = localStorage.getItem('user') || "{}";
    return JSON.parse(cachedData);
}

export const getCachedUser = (): User | null => {
    const cachedData = localStorage.getItem('user') || "{}";
    let parse = JSON.parse(cachedData);
    if(parse.user === null) {
        return null;
    }
    return parse;
}

import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import 'chart.js/auto';
import styles from './Graph.module.css';

const BookGraph: React.FC = () => {
    const [totalBooks, setTotalBooks] = useState(0);
    const [booksPerDay, setBooksPerDay] = useState<{ date: string; count: number }[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const { totalBooks, booksPerDay } = await adminService.getBookStats();
            setTotalBooks(totalBooks);
            setBooksPerDay(booksPerDay);
        };

        fetchData();
    }, []);

    const maxYValue = Math.max(...booksPerDay.map(item => item.count)) * 1.2;

    const data = {
        labels: booksPerDay.map(item => item.date),
        datasets: [
            {
                label: 'Books per Day',
                data: booksPerDay.map(item => item.count),
                fill: false,
                backgroundColor: 'rgb(255, 159, 64)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
                tension: 0.4,
            },
        ],
    };

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#1E3742',
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                suggestedMax: maxYValue,
                ticks: {
                    color: '#1E3742',
                },
                grid: {
                    color: '#E3E5DC',
                },
            },
            x: {
                ticks: {
                    color: '#1E3742',
                },
                grid: {
                    color: '#E3E5DC',
                },
            },
        },
    };

    return (
        <div className={styles.graphContainer}>
            <h3 className={styles.graphTitle}>Total Books: {totalBooks}</h3>
            <div className={styles.chartWrapper}>
                <Line data={data} options={options} />
            </div>
        </div>
    );
};

export default BookGraph;

import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles for toast
import styles from "./LandingForm.module.css";
import { ReactComponent as CheckBox } from "../../assets/author-landing/checkbox.svg";
import landingAuthorService from "../../services/landingAuthorService";
import { trackEvent, useLogEvent} from "../../utils/analytics";
import {EventAction, EventCategory, EventLabel} from "../../constants/analytics";

type FormData = {
    fullName: string;
    email: string;
    bookLink: string;
};

type FormErrors = {
    fullName?: string;
    email?: string;
    bookLink?: string;
    checkbox?: string;
};

const LandingForm: React.FC = () => {
    const logEvent = useLogEvent();

    const [formData, setFormData] = useState<FormData>({
        fullName: "",
        email: "",
        bookLink: "",
    });

    const [errors, setErrors] = useState<FormErrors>({});
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" })); // Clear error when the user types
        setIsSubmitted(false); // Re-enable button if user changes the data
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setErrors((prev) => ({ ...prev, checkbox: "" })); // Clear error if user checks the box
        setIsSubmitted(false); // Re-enable button
    };

    const validate = (): boolean => {
        const newErrors: FormErrors = {};
        if (!formData.fullName.trim()) {
            newErrors.fullName = "Full Name is required.";
        }
        if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Valid email is required.";
        }
        if (!formData.bookLink.trim()) {
            newErrors.bookLink = "Book link is required.";
        } else {
            try {
                new URL(formData.bookLink); // This will throw if the URL is invalid
            } catch (error) {
                newErrors.bookLink = "Book link must be a valid URL.";
            }
        }
        if (!isChecked) {
            newErrors.checkbox = "You must agree to the terms.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (validate()) {
            trackEvent(EventCategory.USER_INTERACTION, EventAction.BUTTON_CLICK, EventLabel.SUBMIT_BUTTON);
            logEvent(EventAction.FORM_SUBMISSION, formData.fullName, formData);
            try {
                await landingAuthorService.submitForm({
                    ...formData,
                    agreement: isChecked,
                });
                // toast.success("Form submitted successfully!");
                setIsSubmitted(true);
            } catch (error: any) {
                console.error("Error submitting form:", error);
                if(error.response?.data?.errors?.length > 0) {
                    const errorMessage = error.response?.data?.errors[0]
                    toast.error(errorMessage);
                    return;
                }
                toast.error(
                    error.response?.data?.message ||
                    "An error occurred while submitting the form."
                );
            }
        }
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.formContentContainer}>
                <div className={styles.formTopSection}>
                    <div className={styles.formTitle}>Ask to Join</div>
                    <div className={styles.formSubTitle}>
                        Let us build your Echo and enhance your intimacy with your readers
                    </div>
                </div>
                <div className={styles.formFieldsContainer}>
                    {/* Full Name Field */}
                    <div className={styles.formField}>
                        <input
                            type="text"
                            name="fullName"
                            className={`${styles.inputField} ${errors.fullName ? styles.errorInput : ""}`}
                            placeholder={errors.fullName || "Full Name"}
                            value={formData.fullName}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.fullName ? <div className={styles.errorText}>{errors.fullName}</div> : <div className={styles.emptyError}/>}

                    {/* Email Field */}
                    <div className={styles.formField}>
                        <input
                            type="text"
                            name="email"
                            className={`${styles.inputField} ${errors.email ? styles.errorInput : ""}`}
                            placeholder={errors.email || "Email"}
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.email ? <div className={styles.errorText}>{errors.email}</div> : <div className={styles.emptyError}/>}

                    {/* Book Link Field */}
                    <div className={styles.formField}>
                        <input
                            type="text"
                            name="bookLink"
                            className={`${styles.inputField} ${errors.bookLink ? styles.errorInput : ""}`}
                            placeholder={errors.bookLink || "Link to your book (amazon or other)"}
                            value={formData.bookLink}
                            onChange={handleChange}
                        />
                    </div>
                    {errors.bookLink ? <div className={styles.errorText}>{errors.bookLink}</div> : <div className={styles.emptyError}/>}

                    {/* Acknowledge Checkbox */}
                    <div className={styles.formAcknowledgeContainer}>
                        <div
                            className={`${styles.formAckCheckBox} ${isChecked ? styles.checked : ""}`}
                            onClick={handleCheckboxChange}
                            role="checkbox"
                            aria-checked={isChecked}
                        >
                            {isChecked && <CheckBox className={styles.checkIcon}/>}
                        </div>
                        <div className={styles.formAckText}>
                            By signing up you agree to our&nbsp;
                            <a
                                href="https://authorsecho.short.gy/wisdom-ts"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                Terms and Conditions
                            </a>
                            &nbsp;and&nbsp;
                            <a
                                href="https://authorsecho.short.gy/wisdom-pp"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                Privacy Policy
                            </a>.
                        </div>

                    </div>
                    {errors.checkbox ? <div className={styles.errorText}>{errors.checkbox}</div> : <div className={styles.emptyError}/>}

                    {/* Submit Button */}
                    <div
                        className={`${styles.formSubmitButton} ${isSubmitted ? styles.disabledButton : ""}`}
                        onClick={isSubmitted ? undefined : handleSubmit}
                    >
                        {isSubmitted ? "Welcome aboard! You're on the list!" : "Get on the list"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingForm;

// src/components/routes/AuthorProviderWrapper.tsx
import React from 'react';
import {useParams, Outlet} from 'react-router-dom';
import {AuthorProvider} from "./AuthorContext";

const AuthorProviderWrapper: React.FC = () => {
    const {authorId} = useParams<{ authorId: string }>();

    if (!authorId) {
        throw new Error('Missing authorId in route params');
    }

    return (
        <AuthorProvider authorId={authorId}>
            <Outlet/>
        </AuthorProvider>
    );
};

export default AuthorProviderWrapper;

import React, {useEffect, useState} from 'react';
import adminService from '../../services/adminService';
import {Book} from '../../interfaces/interfaces';
import BookModal from './BookModal';
import styles from './BookList.module.css';

const BookList: React.FC = () => {
    const [books, setBooks] = useState<Book[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(20);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedBook, setSelectedBook] = useState<Book | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchBooks = async () => {
            const data = await adminService.getBooksWithAuthor(currentPage, pageSize);
            setBooks(data.books);
            setTotalCount(data.totalCount);
        };

        fetchBooks();
    }, [currentPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleDelete = async (bookId: string) => {
        if (window.confirm('Are you sure you want to delete this book?')) {
            await adminService.deleteBook(bookId);
            setBooks((prev) => prev.filter((book) => book.id !== bookId));
        }
    };

    const handleEdit = (book: Book) => {
        setSelectedBook(book);
        setIsModalOpen(true);
    };

    const handleAddBook = () => {
        setSelectedBook(null); // Clear selection for creating a new book
        setIsModalOpen(true);
    };

    const totalPages = Math.ceil(totalCount / pageSize);

    return (
        <div className={styles.bookListContainer}>
            <div className={styles.header}>
                <h2>Books</h2>
                <button className={styles.addButton} onClick={handleAddBook}>
                    Add Book
                </button>
            </div>
            <table className={styles.bookTable}>
                <thead>
                <tr>
                    <th>Cover</th>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {books.map((book) => (
                    <tr key={book.id}>
                        <td>
                            {book.coverUrl ? (
                                <img
                                    src={book.coverUrl}
                                    alt={book.title}
                                    className={styles.bookCover}
                                    onClick={() => book.bookUrl ? window.open(book.bookUrl, '_blank') : null}
                                />
                            ) : (
                                'No Cover'
                            )}
                        </td>
                        <td>{book.title}</td>
                        <td>{book.authorId}</td>
                        <td>
                            <button
                                className={styles.editButton}
                                onClick={() => handleEdit(book)}
                            >
                                Edit
                            </button>
                            <button
                                className={styles.deleteButton}
                                onClick={() => handleDelete(book.id)}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className={styles.pagination}>
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>{`Page ${currentPage} of ${totalPages}`}</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            {isModalOpen && (
                <BookModal
                    book={selectedBook}
                    onClose={() => setIsModalOpen(false)}
                    onSave={(updatedBook: any) => {
                        setBooks((prev) =>
                            selectedBook
                                ? prev.map((book) =>
                                    book.id === updatedBook.id ? updatedBook : book
                                )
                                : [...prev, updatedBook]
                        );
                        setIsModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default BookList;

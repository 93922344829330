import React, { useState } from 'react';
import { User } from '../../interfaces/interfaces';
import styles from './UserModal.module.css';

interface UserModalProps {
    user: User | null;
    onClose: () => void;
    onSave: (user: Partial<User>) => void;
}

const UserModal: React.FC<UserModalProps> = ({ user, onClose, onSave }) => {
    const [formState, setFormState] = useState<Partial<User & { password?: string }>>({
        name: user?.name || '',
        lastName: user?.lastName || '',
        userType: user?.userType || 'regular',
        email: user?.email || '',
        password: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Remove the password field if editing an existing user
        const payload = user ? { ...formState, password: undefined } : formState;

        onSave(payload);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <h2>{user ? 'Edit User' : 'Create User'}</h2>
                {user && (
                    <div className={styles.userId}>
                        <strong>User ID:</strong> {user.id}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <label>
                        Name
                        <input
                            type="text"
                            name="name"
                            value={formState.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Last Name
                        <input
                            type="text"
                            name="lastName"
                            value={formState.lastName}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        User Type
                        <select
                            name="userType"
                            value={formState.userType}
                            onChange={handleChange}
                            required
                        >
                            <option value="author">Author</option>
                            <option value="admin">Admin</option>
                            <option value="regular">Regular</option>
                        </select>
                    </label>
                    <label>
                        Email
                        <input
                            type="email"
                            name="email"
                            value={formState.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    {/* Show password field only for new users */}
                    {!user && (
                        <label>
                            Password
                            <input
                                type="password"
                                name="password"
                                value={formState.password}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    )}
                    {user && (
                        <div>
                            <p>Created At: {user.createdAt}</p>
                            <p>Updated At: {user.updatedAt}</p>
                        </div>
                    )}
                    <div className={styles.actions}>
                        <button type="submit" className={styles.saveButton}>
                            Save
                        </button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserModal;

import React from "react";
import styles from "./LandingSteps.module.css";
import step1Image from "../../assets/author-landing/stepOne-image.png";
import step2Image from "../../assets/author-landing/stepTwo-image.png";
import step3Image from "../../assets/author-landing/stepThree-image.png";

const LandingAuthorSteps = () => {
    const steps = [
        {
            id: 1,
            title: "Create your Echo",
            description: " using all your content",
            image: step1Image,
        },
        {
            id: 2,
            title: "Shape your Echo",
            description: " to reflect you",
            image: step2Image,
        },
        {
            id: 3,
            title: "Share it and deepen",
            description: " your connection with readers",
            image: step3Image,
        },
    ];

    return (
        <div className={styles.stepsContainer}>
            {steps.map((step) => (
                <div className={styles.stepCard} key={step.id}>
                    <img src={step.image} alt={`Step ${step.id}`} className={styles.stepImage}/>
                    <div className={styles.stepBadge}>Step {step.id}</div>
                    <div className={styles.textContainer}>
                        <p className={styles.stepTitle}>
                            {step.title} <span className={styles.stepDescription}>{step.description}</span>
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LandingAuthorSteps;

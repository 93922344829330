// src/components/common/Header.tsx
import React, { useState } from 'react';
import styles from './Header.module.css';
import wisdomLogo from '../../assets/icons/wisdom-logo.svg';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '../../assets/icons/chevron-down.svg'; // Import your menu icon
import CloseIcon from '../../assets/icons/chevron-up.svg';
import {useNavigate} from "react-router-dom"; // Import your close icon

interface HeaderButton {
    label: string;
    onClick: () => void;
}

interface HeaderProps {
    buttons: HeaderButton[];
}

const Header: React.FC<HeaderProps> = ({ buttons }) => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleOnLogoClicked = () => {
        navigate('/');
    }

    return (
        <header className={styles.header}>
            <div className={styles.logoContainer} onClick={handleOnLogoClicked}>
                <img src={wisdomLogo} alt="Wisdom Logo" className={styles.logo} />
            </div>

            {isMobile ? (
                <div className={styles.mobileMenuContainer}>
                    <button onClick={toggleMobileMenu} className={styles.mobileMenuButton}>
                        {isMobileMenuOpen ? (
                            <img src={CloseIcon} alt="Close Menu" />
                        ) : (
                            <img src={MenuIcon} alt="Open Menu" />
                        )}
                    </button>
                    {isMobileMenuOpen && (
                        <div className={styles.mobileMenu}>
                            {buttons.map((button, index) => (
                                <div
                                    key={index}
                                    className={styles.headerButton}
                                    onClick={() => {
                                        button.onClick();
                                        setIsMobileMenuOpen(false); // Close menu after click
                                    }}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                            button.onClick();
                                            setIsMobileMenuOpen(false); // Close menu after click
                                        }
                                    }}
                                >
                                    {button.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className={styles.buttonGroup}>
                    {buttons.map((button, index) => (
                        <div
                            key={index}
                            className={styles.headerButton}
                            onClick={button.onClick}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault();
                                    button.onClick();
                                }
                            }}
                        >
                            {button.label}
                        </div>
                    ))}
                </div>
            )}
        </header>
    );
};

export default Header;
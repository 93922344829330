import React, {useEffect, useState} from 'react';
import adminService from '../../services/adminService';
import {User} from '../../interfaces/interfaces';
import styles from './UserList.module.css';
import UserModal from './UserModal';
import {toast} from "react-toastify";

const UserList: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState<User | null>(null);

    const fetchUsers = async (page: number) => {
        const {users, totalCount} = await adminService.getUsers(page, pageSize);
        setUsers(users);
        setTotalUsers(totalCount);
    };

    const handleCreateUser = () => {
        setEditingUser(null); // Clear the editing user
        setIsModalOpen(true);
    };

    const handleEditUser = (user: User) => {
        setEditingUser(user);
        setIsModalOpen(true);
    };

    const handleDeleteUser = async (userId: string) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                // Implement `deleteUser` in adminService to call backend API
                await adminService.deleteUser(userId);
                fetchUsers(currentPage);
            } catch (error) {
                alert('Failed to delete user.');
            }
        }
    };

    const handleSaveUser = async (user: Partial<User>) => {
        try {
            if (editingUser) {
                // Implement `updateUser` in adminService to call backend API
                await adminService.updateUser(editingUser.id, user);
            } else {
                // Implement `createUser` in adminService to call backend API
                const response = await adminService.createUser(user);
                console.log('Response:', JSON.stringify(response));
            }
            setIsModalOpen(false);
            fetchUsers(currentPage);
        } catch (error: any) {
            console.log('Error:', error);
            if (error.response?.data?.errors[0]) {
                toast.error(error.response.data.errors[0]);
            } else {
                toast.error('Failed to save user. \nError: ' + error.message);
            }
        }
    };

    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage]);

    const totalPages = Math.ceil(totalUsers / pageSize);

    return (
        <div className={styles.userListContainer}>
            <div className={styles.header}>
                <h2>User List</h2>
                <button onClick={handleCreateUser} className={styles.createButton}>
                    Create New User
                </button>
            </div>
            <table className={styles.userTable}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Last Name</th>
                    <th>User Type</th>
                    <th>Email</th>
                    <th>ID</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user) => (
                    <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.lastName}</td>
                        <td>{user.userType}</td>
                        <td>{user.email}</td>
                        <td>{user.id}</td>
                        <td>
                            <button
                                onClick={() => handleEditUser(user)}
                                className={styles.editButton}
                            >
                                Edit
                            </button>
                            <button
                                onClick={() => handleDeleteUser(user.id)}
                                className={styles.deleteButton}
                            >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className={styles.pagination}>
                <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                >
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                    Next
                </button>
            </div>
            {isModalOpen && (
                <UserModal
                    user={editingUser}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSaveUser}
                />
            )}
        </div>
    );
};

export default UserList;

// ProfileSectionMini.tsx
import React from 'react';
import {useAuthor} from '../../context/AuthorContext';
import styles from './ProfileSectionMobile.module.css';
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";

const ProfileSectionMini: React.FC = () => {
    const navigate = useNavigate();

    const {author, loading, error} = useAuthor();
    const {user, loading: userLoading} = useAuth();

    if (loading || userLoading) return <div>Loading...</div>;
    if (error || !author || !user) return <div>Error loading profile.</div>;

    function showAuthorProfile() {
        navigate(`/author/${author!.id}/profile`);
    }

    return (
        <div className={styles.profileMobile}>
            <div className={styles.topRow} onClick={showAuthorProfile}>
                <img
                    className={styles.authorAvatar}
                    src={author.photo}
                    alt={author.name}
                />
            </div>

            <div className={styles.textContainer}>
                <div className={styles.authorGreetings}>Welcome, {user.name}</div>
                <div className={styles.authorSubtitle}>
                    How can I help?
                </div>
            </div>
        </div>
    );
};

export default ProfileSectionMini;

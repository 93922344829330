import React, {useEffect, useState} from 'react';
import {Author, User} from '../../interfaces/interfaces';
import adminService from '../../services/adminService';
import styles from './AuthorModal.module.css';
import EntityDropdown from "../common/EntityDropdown";

interface AuthorModalProps {
    author: Author | null;
    onClose: () => void;
    onSave: (author: Partial<Author>) => void;
}

const AuthorModal: React.FC<AuthorModalProps> = ({author, onClose, onSave}) => {
    const [formState, setFormState] = useState<Partial<Author>>({
        name: author?.name || '',
        biography: author?.biography || '',
        title: author?.title || '',
        location: author?.location || '',
        userId: author?.userId || null,
        user: author?.user || undefined,
    });

    const [users, setUsers] = useState<User[]>([]);
    const [userSearch, setUserSearch] = useState('');
    const [userPage, setUserPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);

    const fetchUsers = async () => {
        const {users, totalCount} = await adminService.getUsers(userPage, 10, userSearch);
        setUsers(users);
        setTotalUsers(totalCount);
    };

    useEffect(() => {
        fetchUsers();
    }, [userPage, userSearch]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const {name, value} = e.target;
        setFormState((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSave(formState);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <h2>{author ? 'Edit Author' : 'Create Author'}</h2>
                {author && (
                    <div className={styles.authorId}>
                        <strong>Author ID:</strong> {author?.id}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <label>
                        Name
                        <input
                            type="text"
                            name="name"
                            value={formState.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Title
                        <input
                            type="text"
                            name="title"
                            value={formState.title || ''}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Biography
                        <textarea
                            name="biography"
                            value={formState.biography || ''}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Associated User
                        <EntityDropdown
                            entityType="users"
                            selectedEntityId={formState.userId || null}
                            selectedEntityLabel={`${formState.user?.name} ${formState.user?.lastName} ${formState.user?.email}` || ''}
                            onChange={(userId) => setFormState((prev) => ({...prev, userId}))}
                        />
                    </label>
                    {author && (
                        <div>
                            <p>Created At: {author.createdAt}</p>
                            <p>Updated At: {author.updatedAt}</p>
                        </div>
                    )}
                    <div className={styles.actions}>
                        <button type="submit" className={styles.saveButton}>
                            Save
                        </button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AuthorModal;

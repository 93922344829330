// src/components/Loading/Loading.tsx

import React from 'react';
import styles from './Loading.module.css';

type LoaderType = 'spinner' | 'dots' | 'skeleton';

interface LoadingProps {
    type?: LoaderType;
    size?: 'small' | 'medium' | 'large';
    color?: string;
    message?: string;
    fullscreen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
                                             type = 'spinner',
                                             size = 'medium',
                                             color = '#21808D',
                                             message = '',
                                             fullscreen = false,
                                         }) => {
    // Handle size dynamically
    const sizeStyle = {
        small: { width: '20px', height: '20px' },
        medium: { width: '40px', height: '40px' },
        large: { width: '60px', height: '60px' },
    }[size];

    const renderSpinner = () => (
        <div
            className={styles.spinner}
            style={{ ...sizeStyle, borderTopColor: color }}
        ></div>
    );

    const renderDots = () => (
        <div className={styles.dotsLoader}>
            <div className={styles.dot} style={{ backgroundColor: color }}></div>
            <div className={styles.dot} style={{ backgroundColor: color }}></div>
            <div className={styles.dot} style={{ backgroundColor: color }}></div>
        </div>
    );

    const renderSkeleton = () => (
        <div className={styles.skeletonLoader}>
            <div className={`${styles.skeleton} ${styles.wide}`}></div>
            <div className={`${styles.skeleton} ${styles.medium}`}></div>
            <div className={`${styles.skeleton} ${styles.narrow}`}></div>
        </div>
    );

    return (
        <div className={`${styles.loadingContainer} ${fullscreen ? styles.fullscreen : ''}`}>
            {type === 'spinner' && renderSpinner()}
            {type === 'dots' && renderDots()}
            {type === 'skeleton' && renderSkeleton()}
            {message && <p className={styles.message}>{message}</p>}
        </div>
    );
};

export default Loading;

// MainPage.tsx

import React, {useEffect, useMemo, useState} from 'react';
import styles from './MainPage.module.css';
import AboutMeModal from './aboutMe/AboutMeModal';
import AuthorList from './authorList/AuthorList';
import Header from "./common/Header";
import {logComponentLoad} from "../utils/debugLogger";
import {useAuth} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";

const AuthorsGallery: React.FC = () => {
    logComponentLoad('MainPage');
    const navigate = useNavigate();
    const {user, loading, logout} = useAuth();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<{ label: string; onClick: () => void }[]>([]); // Initialize as state


    const handleAboutMeClick = () => {
        setIsModalOpen(true);
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleAdminPanelClick = () => {
        if (user?.userType === 'admin') {
            navigate('/admin');
        }
    }

    const memoizedHeaderButtons = useMemo(() => {
        const buttons: { label: string; onClick: () => void }[] = [];

        if (!loading && user) {
            if (user.userType === 'admin') {
                buttons.push({label: 'Admin Panel', onClick: handleAdminPanelClick});
            }
            buttons.push({label: 'About Me', onClick: handleAboutMeClick});
            buttons.push({label: 'Logout', onClick: handleLogout});
        }
        return buttons;
    }, [user, loading]);

    useEffect(() => {
        setHeaderButtons(memoizedHeaderButtons);
    }, [memoizedHeaderButtons]);

    return (
        <div className={styles.mainPage}>
            <Header buttons={headerButtons}/>
            <AuthorList/>
            <AboutMeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}/>
        </div>
    );
};

export default AuthorsGallery;

import React, { useState } from 'react';
import { Book } from '../../interfaces/interfaces';
import styles from './BookModal.module.css';
import adminService from '../../services/adminService';
import { toast } from 'react-toastify';
import EntityDropdown from "../common/EntityDropdown";

interface BookModalProps {
    book: Book | null;
    onClose: () => void;
    onSave: (book: Partial<Book>) => void;
}

const BookModal: React.FC<BookModalProps> = ({ book, onClose, onSave }) => {
    const [formState, setFormState] = useState<Partial<Book>>({
        title: book?.title || '',
        authorId: book?.authorId || undefined,
        author: book?.author || undefined,
        description: book?.description || '',
        voiceId: book?.voiceId || '',
    });
    const [coverFile, setCoverFile] = useState<File | null>(null);
    const [bookFile, setBookFile] = useState<File | null>(null); // Only for adding new books
    const [loading, setLoading] = useState(false);

    const isEditMode = !!book;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const handleCoverUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoverFile(e.target.files?.[0] || null);
    };

    const handleBookFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBookFile(e.target.files?.[0] || null);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (!formState.authorId) {
                return toast.error('Please select an author');
            }

            if (!formState.title) {
                return toast.error('Please enter a title');
            }

            const formData = new FormData();
            formData.append('title', formState.title);
            formData.append('authorId', formState.authorId);
            formData.append('description', formState.description || '');
            formData.append('voiceId', formState.voiceId || '');

            if (coverFile) {
                formData.append('coverFile', coverFile);
            }

            if (!isEditMode && bookFile) {
                formData.append('bookFile', bookFile);
            }

            const response = isEditMode
                ? await adminService.updateBook(formState.authorId, book!.id, formData)
                : await adminService.indexBook(formState.authorId, formData);

            toast.success(`Book ${isEditMode ? 'updated' : 'added'} successfully`);
            onSave(response);
            onClose();
        } catch (error) {
            console.error('Error saving book:', error);
            toast.error(`Failed to ${isEditMode ? 'update' : 'add'} book`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <h2>{isEditMode ? 'Edit Book' : 'Add Book'}</h2>
                {isEditMode && (
                    <div className={styles.bookId}>
                        <strong>Book ID:</strong> {book?.id}
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    <label>
                        Title
                        <input
                            type="text"
                            name="title"
                            value={formState.title || ''}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Author
                        <EntityDropdown
                            entityType="authors"
                            selectedEntityId={formState.authorId || null}
                            selectedEntityLabel={formState.author?.name || ''}
                            onChange={(authorId) =>
                                setFormState((prev) => ({ ...prev, authorId: authorId || undefined }))
                            }
                        />
                    </label>
                    <label>
                        Description
                        <textarea
                            name="description"
                            value={formState.description || ''}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Voice ID
                        <input
                            type="text"
                            name="voiceId"
                            value={formState.voiceId || ''}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Cover
                        <input type="file" accept="image/*" onChange={handleCoverUpload} />
                    </label>
                    {!isEditMode && (
                        <label>
                            Book File
                            <input
                                type="file"
                                accept=".pdf,.epub,.txt,.doc,.docx"
                                onChange={handleBookFileUpload}
                                required
                            />
                        </label>
                    )}
                    <div className={styles.actions}>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                        <button type="button" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BookModal;

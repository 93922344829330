import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {isAuthorsechoHost} from './utils/getCurrentHost';
import AuthorsEchoRoutes from './routes/AuthorsEchoRoutes';
import FindWisdomRoutes from './routes/FindWisdomRoutes';
import {trackPageView} from "./utils/analytics";
import {StatsigProvider, useClientAsyncInit} from '@statsig/react-bindings';
import {runStatsigAutoCapture} from '@statsig/web-analytics';
import {getCachedUser, getUserID} from './utils/userIdentifier';
import {runStatsigSessionReplay} from "@statsig/session-replay";
import {logComponentLoad} from './utils/debugLogger';
import {useAuth} from "./context/AuthContext"; // Import the logger

const REACT_APP_STATSIG_CLIENT_KEY = process.env.REACT_APP_STATSIG_CLIENT_KEY || "";
if (!REACT_APP_STATSIG_CLIENT_KEY || REACT_APP_STATSIG_CLIENT_KEY.length === 0) {
    throw new Error("STATSIG IS NOT INITIALIZED")
}

const AppContent: React.FC = () => {
    const location = useLocation();

    logComponentLoad('AppContent', {location: location.pathname}); // Log when AppContent loads
    const user = getCachedUser();
    const {client} = useClientAsyncInit(REACT_APP_STATSIG_CLIENT_KEY, {
            userID: user?.id || getUserID(),
            email: user?.email,
        },
        {
            environment:
                {
                    tier: process.env.REACT_APP_ENV
                }
        });

    useEffect(() => {
        logComponentLoad('Route Change', {location: location.pathname});
        trackPageView(location.pathname);
    }, [location]);

    useEffect(() => {
        runStatsigAutoCapture(client);
        runStatsigSessionReplay(client);
    }, [client]);

    return (
        <StatsigProvider client={client} loadingComponent={<div>Loading...</div>}>
            {isAuthorsechoHost() || window.location.pathname === '/authors-landing'
                ? <AuthorsEchoRoutes/>
                : <FindWisdomRoutes/>}
        </StatsigProvider>
    )
};

const App: React.FC = () => {
    logComponentLoad('App');
    return <AppContent/>;
};

export default App;

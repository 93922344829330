// client/src/services/authService.ts

import axios from 'axios';
import { User } from '../interfaces/interfaces';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const login = async (email: string, password: string) => {
    try {
        const response = await axios.post(`${API_URL}/users/login`, { email, password });
        localStorage.setItem('token', response.data.token);
        return response.data;
    } catch (error: any) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Login failed');
    }
};

const register = async (email: string, password: string, name: string, lastName: string): Promise<User> => {
    try {
        const response = await axios.post(`${API_URL}/users/register`, { email, password, name, lastName });
        const user = response.data.user;
        if (!user || !user.jwtToken) {
            throw new Error('User not registered');
        }
        localStorage.setItem('token', user.jwtToken);
        return user;
    } catch (error: any) {
        console.error('Registration error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Registration failed');
    }
};

const getUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');

    try {
        const response = await axios.get(`${API_URL}/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const user = response.data;

        // Save user to localStorage along with a timestamp
        const timestamp = Date.now();
        localStorage.setItem('user', JSON.stringify({ user, timestamp }));

        return user;
    } catch (error: any) {
        console.error('Fetching user details error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Failed to fetch user details');
    }
};

// ✅ Added logout function to clear the token from localStorage
const logout = () => {
    localStorage.removeItem('token');
};

const authService = {
    login,
    register,
    getUserDetails,
    logout, // 🚀 Export the logout function
};

export default authService;
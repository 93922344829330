// client/src/components/authorPage/AuthorPage.tsx
import React, {useEffect, useMemo, useState} from 'react';
import Header from '../common/Header'; // Updated import path
import ProfileSectionFull from './ProfileSectionFull';
import MainContent from './MainContent';
import styles from './AuthorPage.module.css';
import {useNavigate, useParams} from 'react-router-dom';
import {logComponentLoad} from "../../utils/debugLogger";
import {useMediaQuery} from 'react-responsive';
import ProfileSectionMini from "./ProfileSectionMini";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import AboutMeModal from "../aboutMe/AboutMeModal";

const AuthorPage: React.FC = () => {
    logComponentLoad("AuthorPage");
    const {user, loading, logout} = useAuth();
    const navigate = useNavigate();
    const {authorId} = useParams<{ authorId: string }>();
    const isMobile = useMediaQuery({maxWidth: 768});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<{ label: string; onClick: () => void }[]>([]); // Initialize as state


    if (!authorId) {
        throw new Error('Author ID is missing.');
    }

    // Replicate the "Coming Soon" toast functionality
    const handleComingSoon = () => {
        toast.info('Coming soon!', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            theme: 'light',
        });
    };

    const handleAboutMeClick = () => {
        setIsModalOpen(true);
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const memoizedHeaderButtons = useMemo(() => {
        const buttons: { label: string; onClick: () => void }[] = [
            {label: 'Settings', onClick: handleComingSoon},
        ];

        if (!loading && user) {
            console.log("User loaded. Type:", user.userType);
            if (user.userType === 'regular') {
                buttons.push({label: 'About me', onClick: handleAboutMeClick});
            }
            if (user.userType === 'author') {
                buttons.push({label: 'Readership Dashboard', onClick: handleComingSoon});
            }
            if (user.userType === 'admin') {
                buttons.push({label: 'Readership Dashboard', onClick: handleComingSoon});
                buttons.push({label: 'About me', onClick: handleAboutMeClick});
            }
            buttons.push({label: 'Logout', onClick: handleLogout});
        }
        return buttons;
    }, [user, loading]); // Dependencies for useMemo

    useEffect(() => {
        setHeaderButtons(memoizedHeaderButtons);
    }, [memoizedHeaderButtons]);

    return (
        <div className={styles.authorPage}>
            <Header buttons={headerButtons}/>
            <div className={styles.content}>
                {isMobile ? (
                    <ProfileSectionMini/>
                ) : (
                    <ProfileSectionFull/>
                )}
                <MainContent/>
            </div>
            <AboutMeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

        </div>
    );
};

export default AuthorPage;
import React, { useMemo } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Stats from './Stats';
import UserList from './UserList';
import BookList from './BookList';
import AuthorList from './AuthorList';
import Sidebar from './Sidebar';
import Header from '../common/Header';
import styles from './AdminDashboard.module.css';
import {useAuth} from "../../context/AuthContext";

const AdminDashboard: React.FC = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout()
        navigate('/login');
    };

    const headerButtons = useMemo(() => [
        { label: 'Logout', onClick: handleLogout },
    ], []);

    return (
        <div className={styles.adminDashboard}>
            <Header buttons={headerButtons} />
            <div className={styles.dashboardLayout}>
                <Sidebar />
                <div className={styles.dashboardContent}>
                    <Routes>
                        <Route path="/" element={<Navigate to="stats" />} />
                        <Route path="stats" element={<Stats />} />
                        <Route path="users" element={<UserList />} />
                        <Route path="authors" element={<AuthorList />} />
                        <Route path="books" element={<BookList />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;

// client/src/context/AuthContext.tsx

import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
    useMemo,
    useCallback,
} from 'react';
import authService from '../services/authService';
import { User } from '../interfaces/interfaces';
import { areUsersEqual } from '../utils/usersUtil';

interface AuthContextProps {
    user: User | null;
    loading: boolean;
    setUser: React.Dispatch<React.SetStateAction<User | null>>;
    reloadUser: () => Promise<void>;
    logout: () => void; // ✅ Add logout function to the context
}

interface AuthProviderProps {
    children: ReactNode;
}

const CACHE_EXPIRATION_TIME = 60 * 1000; // 1 minute
const USER_CACHE_KEY = 'user'; // Define a constant for the cache key

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    const ifCacheIsExpired = useCallback((timestamp: number): boolean => {
        return Date.now() - timestamp > CACHE_EXPIRATION_TIME;
    }, []);

    const storeUserToCache = useCallback((user: User) => {
        const timestamp = Date.now();
        localStorage.setItem(USER_CACHE_KEY, JSON.stringify({ user, timestamp }));
    }, []);

    const reloadUser = useCallback(async () => {
        setLoading(true);
        try {
            const freshUserData = await authService.getUserDetails();
            storeUserToCache(freshUserData);
            setUser(freshUserData);
        } catch (error) {
            console.error('Failed to reload user', error);
            // Consider more granular error handling here, like showing a notification
        } finally {
            setLoading(false);
        }
    }, [storeUserToCache]);

    const fetchUser = useCallback(async () => {
        setLoading(true);
        try {
            const cachedData = localStorage.getItem(USER_CACHE_KEY);
            if (cachedData) {
                const { user: cachedUser, timestamp } = JSON.parse(cachedData);
                if (cachedUser) {
                    setUser(cachedUser);
                    if (!timestamp || ifCacheIsExpired(timestamp)) {
                        try {
                            const freshUserData = await authService.getUserDetails();
                            if (!areUsersEqual(cachedUser, freshUserData)) {
                                storeUserToCache(freshUserData);
                                setUser(freshUserData);
                            }
                        } catch (freshDataError) {
                            console.error('Failed to fetch fresh user data', freshDataError);
                            // Consider whether to revert to cached data or show an error
                        }
                    }
                }
            } else {
                const freshUserData = await authService.getUserDetails();
                storeUserToCache(freshUserData);
                setUser(freshUserData);
            }
        } catch (error) {
            console.error('Failed to fetch user', error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    }, [ifCacheIsExpired, storeUserToCache]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    // 🚪 Implemented the logout function
    const logout = useCallback(() => {
        authService.logout(); // Clear the token
        localStorage.clear(); // Clear user data from cache
        setUser(null); // Update the user state to null
    }, []);

    const contextValue = useMemo(
        () => ({ user, loading, setUser, reloadUser, logout }), // 🚀 Include logout in the context value
        [user, loading, setUser, reloadUser, logout]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};